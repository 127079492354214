import Grid from '@lendi-ui/grid';
import { Body } from '@lendi-ui/typography';
import { PlatformPathname } from '@lendi/core-constants';
import { Brand, useSession } from '@lendi/lala-react';
import { Origin, createLead } from '@lendi/lendigroup-leads-library';
import { useFlags } from 'launchdarkly-react-client-sdk';
import * as React from 'react';
import { Form } from 'react-final-form';
import Disclaimer from '../components/DisclaimerComponent';
import Divider from '../components/DividerComponent';
import EncriptionText from '../components/EncryptionComponent';
import { AlertError } from '../components/ErrorComponents';
import { MobileField } from '../components/FieldComponents';
import { GoogleSignInButton } from '../components/GoogleSignInComponent';
import { LDContext } from '../components/LaunchDarkly';
import { BoldHeading } from '../components/atoms/BoldHeading';
import { Button } from '../components/atoms/ButtonWithMargin';
import { getReferrer, mapSignInTranslationKey, useRedirect } from '../helpers';
import { setPrlAndReferralParamsToStorage } from '../helpers/setPrlAndReferralParamsToStorage';
import Router from '../helpers/router';
import { eventTracking, pageTracking } from '../helpers/tracking';
import { formatInitialPhoneNumber, trimPhoneNumber } from '../helpers/util';
import { validate } from '../helpers/validation';
import { useTranslate } from '../hooks';
import { AuthenticationFlowLayout } from '../layout/AuthenticationFlowLayout';
import { AuthForm } from '../pages-styles/index.style';
import { useActions } from '../state/actionProvider';
import { useLALACustomerAppContext } from '../state/state';
import Postcode from './postcode';

const Login = () => {
  const { phoneNumber, error, loading, codeSent } = useLALACustomerAppContext();
  const { sendCode, resetError, resetSendCode } = useActions();
  const { subbiesPostcodeBeforeAuth, ssoSignIn } = useFlags();
  const { brand } = useSession();
  const [postcodeHidden, hidePostcode] = React.useState(false);
  const [referrer, setReferrer] = React.useState<string>('');
  const { identifyCalled, ldReady } = React.useContext(LDContext);
  const params = new URLSearchParams(window.location.search);
  const textKey = params.get('textkey');
  const authFilter = params.get('auth');
  const hideOtp = authFilter === 'sso';
  const hideSso = authFilter === 'otp';
  const translationKeys = mapSignInTranslationKey(referrer, textKey ?? undefined);
  const [title, subtitle] = useTranslate([translationKeys.title, translationKeys.subtitle]);
  setPrlAndReferralParamsToStorage();

  const handleSubmit = (values: { [name: string]: any }) => {
    eventTracking.trackAuthButtonClickEvent('OTP', 'Get one-time passcode');
    const phone = `+61${trimPhoneNumber(values.phoneNumber)}`;
    sessionStorage.setItem('lala-phone', phone);
    sendCode(phone, undefined, () => {
      Router.push({ pathname: '/verify', query: Router.query() });
    });
  };
  React.useEffect(() => {
    resetSendCode();
  }, []);
  React.useEffect(() => {
    const documentReferrer = getReferrer() || '';
    setReferrer(documentReferrer);
    if (!identifyCalled) return;
    if (documentReferrer.includes(PlatformPathname.BOOKING_APPOINTMENT_BUYERS_AGENT_PATHNAME)) {
      pageTracking.trackAuthPage(pageTracking.authTrackingPageName.BAA_BUYERS_AGENT_SIGN_IN);
    } else if (documentReferrer.includes(PlatformPathname.BOOKING_APPOINTMENT_PATHNAME)) {
      pageTracking.trackAuthPage(pageTracking.authTrackingPageName.BAA_SIGN_IN);
      const L3 = createLead(Origin.AppointmentBooking);
      L3.set({ accountOrigin: `${window.location.host}${PlatformPathname.BOOKING_APPOINTMENT_PATHNAME}` });
    } else if (documentReferrer.includes(PlatformPathname.STAMP_DUTY_PATHNAME)) {
      pageTracking.trackAuthPage(pageTracking.authTrackingPageName.STAMP_DUTY_SIGN_IN);
    } else if (documentReferrer.includes(PlatformPathname.PROPERTY_REPORT_PATHNAME)) {
      pageTracking.trackAuthPage(pageTracking.authTrackingPageName.PROPERTY_REPORT_SIGN_IN);
    } else if (documentReferrer.includes(PlatformPathname.REFER_A_FRIEND_PATHNAME)) {
      pageTracking.trackAuthPage(pageTracking.authTrackingPageName.REFER_A_FRIND_SIGN_IN);
    } else if (
      documentReferrer.includes(
        PlatformPathname.CHECK_YOUR_CREDIT_SCORE_PATHNAME ||
          PlatformPathname.FREE_CREDIT_SCORE_CHECK_PATHNAME ||
          PlatformPathname.CREDIT_SCORE_RESULT_PATHNAME
      )
    ) {
      pageTracking.trackAuthPage(pageTracking.authTrackingPageName.CREDIT_SCORE_SIGN_IN);
    } else {
      if (textKey === 'returned') {
        pageTracking.trackAuthPage(pageTracking.authTrackingPageName.SIGN_IN_MARKETING);
      }
      pageTracking.trackAuthPage(pageTracking.authTrackingPageName.SIGN_IN);
    }
  }, [identifyCalled]);

  useRedirect();
  return ldReady ? (
    <AuthenticationFlowLayout>
      {brand === Brand.Aussie && subbiesPostcodeBeforeAuth && !postcodeHidden ? (
        <Postcode hidePostcode={hidePostcode} />
      ) : (
        <>
          <BoldHeading
            size="lg"
            as="h1"
            color="shade.700"
            mt="xl"
            mb="sm"
            align="center"
            data-testid="login-page-heading"
          >
            {title}
          </BoldHeading>
          <Body size="lg" color="shade.600" align="center" mt="md" mb="lg">
            {subtitle}
          </Body>
          <Grid halign="center">
            <Grid.Unit size={{ xs: 1, md: 2 / 3 }}>
              {!hideOtp && (
                <Form
                  initialValues={{ phoneNumber: formatInitialPhoneNumber(phoneNumber || '') }}
                  validate={validate}
                  onSubmit={handleSubmit}
                >
                  {({ handleSubmit, invalid }) => (
                    <AuthForm style={{ textAlign: 'center' }} onSubmit={handleSubmit}>
                      <MobileField autoFocus error={error} resetAuthError={resetError} />
                      <AlertError error={error} />
                      <Button
                        type="submit"
                        mt="md"
                        isFullWidth
                        variant="primary"
                        data-testid="get-verification-code"
                        isDisabled={invalid || loading || codeSent}
                      >
                        Get one-time passcode
                      </Button>
                    </AuthForm>
                  )}
                </Form>
              )}
              {!hideSso && ssoSignIn && (
                <>
                  {!hideOtp && <Divider />}
                  <GoogleSignInButton verifying={loading} error={error} />
                </>
              )}
            </Grid.Unit>
            <div>
              <Disclaimer buttonText={hideOtp ? 'SIGN IN WITH GOOGLE' : 'GET ONE-TIME PASSCODE'} />
              <EncriptionText />
            </div>
          </Grid>
        </>
      )}
    </AuthenticationFlowLayout>
  ) : (
    <></>
  );
};
Login.displayName = 'Login';
export default Login;
